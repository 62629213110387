<template>
  <Sidebar ref="sidebar" :title="form.label">
    <form @submit.prevent="save" class="pb-20">

      <Overlay v-if="loading" class="z-2 mt-16 flex justify-center items-center bg-white">
        <div class="bg-white shadow rounded w-8 h-8 flex justify-center items-center text-prasset-green-500">
          <LoadingIndicator />
        </div>
      </Overlay>

      <div class="relative z-1 grid grid-cols-6 gap-6">
        <FieldsGenerator
          v-if="selectedComponentType && selectedComponentType.content_fields"
          :errors="errors"
          :fields="selectedComponentType.content_fields"
          :data="form.content"
          :fileCollection="form.media"
          :entryId="entryId"
          entryType="websitecomponent"
          class="col-span-6"
        />

        <FieldsGenerator
          v-if="selectedComponentType && selectedComponentType.options_fields"
          :errors="errors"
          :fields="selectedComponentType.options_fields"
          :data="form.options"
          :entryId="entryId"
          entryType="websitecomponent"
          class="col-span-6 border-t border-gray-300 pt-4"
        />
      </div>

      <div class="w-full bg-white border-t border-gray-300 absolute bottom-0 inset-x-0 px-5 py-4 z-20">
        <div class="flex">
          <button class="button button--outlined mr-4" type="button" @click="$refs.sidebar.close()">
            {{ $tuf('cancel') }}
          </button>

          <button
            type="button"
            class="button button--outlined relative mr-4"
            :class="{ 'opacity-25': loading && submittedBy !== 'save' }"
            :disabled="loading"
            @click="save(false, 'save')"
          >
            <span :class="{ 'invisible': loading && submittedBy === 'save' }">
              {{ $tuf('save') }}
            </span>
            <span v-if="loading && submittedBy === 'save'" class="absolute inset-0 flex justify-center items-center">
              <LoadingIndicator />
            </span>
          </button>

          <button
            type="submit"
            class="button button--opague relative"
            :class="{ 'opacity-25': loading && submittedBy !== 'save-and-back' }"
            :disabled="loading"
          >
            <span :class="{ 'invisible': loading && submittedBy === 'save-and-back' }">
              {{ $tuf('save_and_back') }}
            </span>
            <span v-if="loading && submittedBy === 'save-and-back'" class="absolute inset-0 flex justify-center items-center">
              <LoadingIndicator />
            </span>
          </button>
        </div>
      </div>
    </form>
  </Sidebar>
</template>

<script>
import { toRefs, reactive, computed, onMounted } from '@vue/composition-api';
import FieldsGenerator from '@/modules/core/views/components/FieldsGenerator';
import LoadingIndicator from '@/components/LoadingIndicator';
import Overlay from '@/components/Overlay';
import Sidebar from '@/modules/core/views/components/Sidebar';
import useWebsite from '@/compositions/useWebsite';
import useChangedState from '@/compositions/useChangedState';

export default {
  name: 'ComponentEdit',

  components: {
    FieldsGenerator,
    LoadingIndicator,
    Overlay,
    Sidebar,
  },

  setup(props, { refs, root }) {
    const { project_id, page_id, component_id } = root.$route.params;
    const { fetchComponent, updateComponent, reloadPage, componentTypes, loadComponentTypes } = useWebsite();
    const { changed, watchChanges, commitChanges } = useChangedState();

    const state = reactive({
      form: {},
      errors: {},
      ready: false,
      changed,
      loading: false,
      submittedBy: null,
    });

    /**
     * Selected component object.
     */
    const selectedComponentType = computed(() => {
      return componentTypes.value[state.form.component] || null;
    });

    /**
     * Load form data.
     */
    async function load() {
      state.loading = true;

      await loadComponentTypes(project_id);
      const data = await fetchComponent(project_id, component_id);

      state.form = {
        component: data.component,
        label: data.label,
        content: Object.assign({}, data.content), // array to obj.
        options: Object.assign({}, data.options), // array to obj.
        media: data.media,
      };

      state.loading = false;
      state.ready = true;

      watchChanges(state, 'form');
    }

    /**
     * Save form data.
     */
    async function save(close = true, submittedBy = 'save-and-back') {
      state.submittedBy = submittedBy;
      state.loading = true;
      state.errors = {};

      try {
        await updateComponent(project_id, component_id, state.form);
        await reloadPage(project_id, page_id);

        root.$notify({ type: 'success', title: 'Succes', text: 'Onderdeel is opgeslagen' });

        state.loading = false;

        commitChanges(state, 'form');

        if (close) {
          refs.sidebar.close();
        }
      } catch (error) {
        state.loading = false;
        state.errors = error.response.data.errors;
      }
    }

    onMounted(() => {
      load();
    });

    return {
      ...toRefs(state),
      save,
      selectedComponentType,
      entryId: component_id,
    };
  },
};
</script>
